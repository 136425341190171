input {
    padding: 16px;
}

input:focus {
    border: 2px solid var(--primary);
    border-radius: 2px;
    outline: none;
}

button {
    padding: 16px 32px;
    background-color: var(--primary);
    color: white;
    border: none;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    opacity: .8;
}

.result-container {
    margin-top: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    color: var(--primary);
    opacity: 1;
    font-weight: bold;
}

.content {
    /* border: 1px solid var(--primary); */
    display: flex;
    align-items: center;
    /* padding: 16px; */
}

.error {
    padding: 8px;
    color: red;
}

.copy {
    border-radius: 100%;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.copy:hover {
    background-color: #5f63681f;
}

.outline-button {
    border: 1px solid var(--primary);
    padding: 12px 32px;
    border-radius: 4px;
    color: var(--primary);
    font-weight: bold;
    cursor: pointer;
}